<script>
export default {
    props: {
        variant: Object,
        technique: Object,
        marking: Object
    }
}
</script>

<template>
    <div class="cartitem">
        <div class="cartitem-thumb is-image">
            <img v-if="variant.urlIcon" :src="variant.urlIcon.replace('web-tab', 'localhost:8000')" alt="">
        </div>
        <div class="cartitem-name">
            <b>{{ technique.label }} {{ variant.label }}</b> ({{ marking.label }})
        </div>
        <div class="cartitem-price">
            xx,xx € HT
        </div>
        <button class="cartitem-delete" title="Supprimer" @click.prevent="$emit('delete')"></button>
    </div>
</template>
