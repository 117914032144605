<template>
    <div class="stock" :class="{ 'is-small': small }">
        <span class="stock-status is-available" v-if="count > 0">
            <svg-sprite icon="stock" width="12" height="12" />
            <span v-if="!small">en stock</span>
        </span>
        <span class="stock-status" v-if="count < 1">
            <svg-sprite icon="no-stock" width="12" height="12" />
        </span>
        <span class="stock-count"> {{ count }}</span>
    </div>
</template>

<script>
    export default {
        name: 'Stock',
        props: {
            small: Boolean,
            count: Number
        }
    }
</script>
