<template>
    <div class="viewer-product js-unselect-item" :style="style">
        <ViewerProductImage v-for="(image, index) in marquage.images" :key="index" :image="image" :showed="currentProductImage == image.url"/>
        <div class="viewer-product-marking js-unselect-item" :style="markingStyle" :class="{'is-round': technique.diameter, 'is-dark': darkMode, 'has-no-ui': showRules === 2}">
            <div class="marking-bubble">
                {{ $t('marking.size') }} :
                <span v-if="technique.diameter">{{ technique.diameter }}</span>
                <span v-else>{{ technique.widthCm }} x {{ technique.heightCm }}</span>
                cm
            </div>
            <ViewerMarkingRule
                :size="technique.diameter ? technique.diameter : technique.widthCm"
                :vertical="false"
                v-if="showRules === 1"
            />
            <ViewerMarkingRule
                :size="technique.diameter ? technique.diameter : technique.heightCm"
                :vertical="true"
                v-if="showRules === 1"
            />
            <EditorText
                v-for="(text, index) in texts"
                :item="text"
                :itemIndex="index"
                :key="index"
                :dimensions="dimensions"
                :zoom="zoom"
                :pxByCm="pxByCm"
                :laserColor="technique.laserColor"
            ></EditorText>
            <EditorImage
                v-for="(image, index) in images"
                :item="image"
                :itemIndex="index"
                :key="index"
                :dimensions="dimensions"
                :zoom="zoom"
                :pxByCm="pxByCm"
                :laserColor="technique.laserColor"
            ></EditorImage>
            <EditorClipart
                v-for="(motif, index) in motifs"
                :item="motif"
                :itemIndex="index"
                :key="index"
                :dimensions="dimensions"
                :zoom="zoom"
                :pxByCm="pxByCm"
                :laserColor="technique.laserColor"
            ></EditorClipart>
        </div>
    </div>
</template>

<script>
    import EditorText from '../editor/EditorText'
    import EditorImage from '../editor/EditorImage'
    import Constants from '../../config/Constants'
    import ViewerProductImage from './ViewerProductImage'
    import ViewerMarkingRule from './ViewerMarkingRule'
    import EditorClipart from '../editor/EditorClipart'
    import Hammer from 'hammerjs'

    export default {
        name: 'ViewerProduct',
        components: {EditorClipart, ViewerMarkingRule, ViewerProductImage, EditorText, EditorImage},
        props: {
            marquage: Object,
            marquageSlug: String,
            viewerSize: Object,
            active: Boolean,
            showRules: Number,
            darkMode: Boolean
        },
        data: () => ({
            zoom: 1,
            pinchInitialZoom: 1
        }),
        mounted() {
            this._onZoomBind = this.onZoom.bind(this)
            this.$parent.$parent.$on('zoom', this._onZoomBind)
            this.hammertime = new Hammer(this.$el)
            this.hammertime.get('pinch').set({ enable: true })
            this.hammertime.on('pinch', this.onPinch)
            this.hammertime.on('pinchstart', this.onPinchStart)
            this.initZoom()
        },
        beforeDestroy() {
            this.$parent.$parent.$off('zoom', this._onZoomBind)
            this.hammertime.off('pinch', this.onPinch)
            this.hammertime.off('pinchstart', this.onPinchStart)
        },
        watch: {
            viewerSize() {
                this.onZoom(0)
            },
            /**
             * position of marking area change, offset items to stay in same position
             */
            'technique.startX'(newV, oldV) {
                this.$store.dispatch('editor/offsetItemsPosition', {
                    direction: 'x',
                    offset: oldV - newV,
                    marquage: this.marquage.id
                })
            },
            'technique.startY'(newV, oldV) {
                this.$store.dispatch('editor/offsetItemsPosition', {
                    direction: 'y',
                    offset: oldV - newV,
                    marquage: this.marquage.id
                })
            },
            /**
             * nb colors change, update items colors
             * */
            'technique.nbColorIncluded'(newV, oldV) {
                if((newV >= 3 && oldV <= 2) || (newV <= 2 && oldV >= 2)) {
                    const items = this.$store.getters['editor/itemsByMarquage']({
                        marquage: this.marquageSlug,
                        type: null
                    })
                    const itemsColors = this.$store.getters['editor/itemsColors'](this.marquageSlug)
                    if(newV <= 2) {
                        // 1 or 2 colors only, set same color for all items
                        let newColor
                        if(this.technique.laserColor) {
                            newColor = this.technique.laserColor
                        } else {
                            newColor = this.$store.getters['editor/latestItemColor'](this.marquage.id)
                        }
                        for(let [iIndex, item] of Object.entries(items)) {
                            if(newV === 2 && itemsColors.length <= 2) {
                                // 2 colors and we already have less than 2, dont change texts and cliparts color
                            } else {
                                this.$store.commit('editor/updateItemColor', {index: iIndex, color: newColor})
                            }

                            if(item.type === Constants.EDITOR.TYPE_IMAGE) {
                                let imageOptions = {...item.options}
                                imageOptions.nbColors = newV
                                imageOptions.color2 = newColor
                                imageOptions.dominantColor = newColor
                                this.$store.commit('editor/updateItemProperty', {
                                    index: iIndex,
                                    name: 'options',
                                    value: imageOptions
                                })
                            }
                        }
                    } else if(newV > 2) {
                        // multicolor, dont change image colors
                        for(let [iIndex, item] of Object.entries(items)) {
                            if(item.type === Constants.EDITOR.TYPE_IMAGE) {
                                let imageOptions = {...item.options}
                                imageOptions.nbColors = newV
                                this.$store.commit('editor/updateItemProperty', {
                                    index: iIndex,
                                    name: 'options',
                                    value: imageOptions
                                })
                            }
                        }
                    }
                }
            }
        },
        computed: {
            technique() {
                return this.$store.getters['marquage/currentTechnique'](this.marquage.id)
            },
            texts() {
                return this.$store.getters['editor/itemsByMarquage']({
                    marquage: this.marquageSlug,
                    type: Constants.EDITOR.TYPE_TEXT,
                })
            },
            images() {
                return this.$store.getters['editor/itemsByMarquage']({
                    marquage: this.marquageSlug,
                    type: Constants.EDITOR.TYPE_IMAGE,
                })
            },
            currentProductImage() {
                const options = this.$store.getters['variants/selectedOptions']
                const subvariantSelected = this.$store.getters['variants/subvariantSelected']
                let currentImg = null
                console.log(this.marquage.images);
                for(const img of this.marquage.images) {
                    for(const variant in options) {
                        if(img.variant == options[variant]) {
                            if(!subvariantSelected || subvariantSelected == img.subVariant) {
                                currentImg = img
                            }
                        }
                    }
                }
                if(!currentImg) {
                    currentImg = this.marquage.images[0]
                }
                return currentImg ? currentImg.url : ''
            },
            motifs() {
                return this.$store.getters['editor/itemsByMarquage']({
                    marquage: this.marquageSlug,
                    type: Constants.EDITOR.TYPE_CLIPART,
                })
            },
            /**
             * pixels number by cm
             */
            pxByCm() {
                const heightPx = this.technique.diameter ? this.technique.diameter : this.technique.heightCm
                const heightCm = this.technique.height
                return heightCm / heightPx
            },
            /**
             * compute dimensions of product element, depending on image size, zoom and marking position
             * @returns Object
             */
            dimensions() {
                const vw = this.viewerSize.width,
                    vh = this.viewerSize.height - 100,
                    iw = Math.round(this.technique.maxX * this.zoom),
                    ih = Math.round(this.technique.maxY * this.zoom),
                    mw = Math.round(this.technique.width * this.zoom),
                    mh = Math.round(this.technique.height * this.zoom),
                    mx = Math.round(this.technique.startX * this.zoom),
                    my = Math.round(this.technique.startY * this.zoom)

                let iy = (vh - ih) / 2,
                    ix = (vw - iw) / 2

                // image too big, center on marking to make sure it is visible on screen
                if (ih > vh) {
                    iy = this.centerOnMarking(vh, ih, mh, my)
                }
                if (iw > vw) {
                    ix = this.centerOnMarking(vw, iw, mw, mx)
                }

                return {
                    image: {
                        x: ix,
                        y: iy,
                        width: iw,
                        height: ih,
                    },
                    marquage: {
                        x: mx,
                        y: my,
                        width: mw,
                        height: mh,
                    },
                }
            },
            /**
             * compute element styles based on dimensions
             * @returns Object
             */
            style() {
                const styles = {
                    width: this.dimensions.image.width + 'px',
                    height: this.dimensions.image.height + 'px',
                    top: this.dimensions.image.y + 'px',
                    left: this.dimensions.image.x + 'px',
                }

                // light mode ? add background color
                if(this.$store.getters['product/light']) {
                    const selectedColor = this.$store.getters['variants/selectedColor']
                    styles.backgroundColor = selectedColor[0]
                    styles.borderRadius = '10px'
                }

                return styles
            },
            /**
             * compute marking element styles based on dimensions
             * @returns Object
             */
            markingStyle() {
                return {
                    width: this.dimensions.marquage.width + 'px',
                    height: this.dimensions.marquage.height + 'px',
                    top: this.dimensions.marquage.y + 'px',
                    left: this.dimensions.marquage.x + 'px',
                }
            },
        },
        methods: {
            onPinchStart(event) {
                this.pinchInitialZoom = this.zoom
            },
            onPinch(event) {
                const targetZoom = this.pinchInitialZoom * event.scale
                this.onZoom(0, targetZoom)
            },
            /**
             * zoom in or out
             * @param direction
             * @param target
             */
            onZoom(direction, target = false) {
                if (!this.active) {
                    return
                }
                const paddingMin = this.$store.getters['product/light'] ? 300 : 80
                const paddingMax = 80
                // min zoom : image fit in viewport
                const minZoom = Math.min(
                    (this.viewerSize.width - paddingMin) / this.technique.maxX,
                    (this.viewerSize.height - paddingMin) / this.technique.maxY,
                    1,
                )
                // max zoom : marquage contained in viewport
                const maxZoom = Math.min(
                    (this.viewerSize.width - paddingMax) / this.technique.width,
                    (this.viewerSize.height - paddingMax) / this.technique.height,
                )

                // update zoom
                const targetZoom = target ? target : this.zoom * (1 + (direction / 4))
                this.zoom = Math.min(Math.max(targetZoom, minZoom), maxZoom)

                this.zoomTimer && clearTimeout(this.zoomTimer)
                this.zoomTimer = setTimeout(() => {
                    this.$emit('zoomEnd')
                }, 300)
            },
            /**
             * get image position to center the view on marking, to make sure it's visible
             * @param viewSize
             * @param imageSize
             * @param markingSize
             * @param markingPosition
             * @returns {number}
             */
            centerOnMarking(viewSize, imageSize, markingSize, markingPosition) {
                const ratio = (viewSize - markingSize) / (imageSize - markingSize)
                return -(markingPosition * (1 - ratio))
            },
            /**
             * init zoom, to fit image on viewport
             */
            initZoom() {
                const vw = this.viewerSize.width,
                    vh = this.viewerSize.height,
                    iw = this.technique.maxX,
                    ih = this.technique.maxY,
                    ratio = this.$store.getters['product/light'] ? 0.7 : 1

                if(iw / vw > ih / vh) {
                    // image too wide
                    this.zoom = vw / iw * ratio
                } else {
                    // image too tall
                    this.zoom = vh / ih * ratio
                }
            },
        },
    }
</script>
