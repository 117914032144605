<template>
    <div class="grilletarif">
        <div class="grilletarif-head">
            <div>
                <div class="grilletarif-title"><b>Grille tarifaire</b> (en &euro; HT)</div>
                <a href="#" class="link is-small">Voir tous les prix</a>
            </div>
            <div>
                <div class="price" v-if="prices.unit"><b>{{ totalQuantity }}</b> unités <span class="price-value">{{ prices.unit | price }}</span> / unité</div>
            </div>
        </div>
        <table class="grilletarif-table" v-if="grille && grille.length">
            <thead>
            <tr>
                <th>Quantité</th>
                <th v-for="step in grille[0].steps" :key="step.count" class="is-price">{{ step.count }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="price in grille">
                <td>{{ price.label }}</td>
                <td v-for="(step, index) in price.steps" class="is-price">
                    <span class="grilletarif-table-price" :class="{ 'is-active' : index == activeColumnIndex }">{{ step.price | price(false) }}</span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    data: () => ({
        grille: [
            {
                label: "Sans marquage",
                steps: [
                    {
                        count: 25,
                        price: 12.5
                    },
                    {
                        count: 50,
                        price: 12.2
                    },
                    {
                        count: 250,
                        price: 11.25
                    },
                    {
                        count: 500,
                        price: 10.3
                    },
                    {
                        count: 1000,
                        price: 9.65
                    }
                ]
            },
            {
                label: "Sérigraphie 1 couleur",
                steps: [
                    {
                        count: 25,
                        price: 1.2
                    },
                    {
                        count: 50,
                        price: 1
                    },
                    {
                        count: 250,
                        price: 0.90
                    },
                    {
                        count: 500,
                        price: 0.85
                    },
                    {
                        count: 1000,
                        price: 0.72
                    }
                ]
            }
        ]
    }),
    computed: {
        ...mapGetters({
            prices: 'cart/prices',
            totalQuantity: 'variants/totalQuantity'
        }),
        activeColumnIndex() {
            const qty = this.totalQuantity
            let index = -1
            if(this.grille.length) {
                const row = this.grille[0]
                for(const i in row.steps) {
                    if(row.steps[i].count <= qty) {
                        index = i
                    }
                }
            }
            return index
        }
    },
}
</script>
