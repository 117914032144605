<script>
import VariantSpot from "../variant/VariantSpot.vue";

export default {
    components: {VariantSpot},
    props: {
        option: Object,
    }
}
</script>

<template>
    <div class="cartitem">
        <div class="cartitem-thumb">
            <VariantSpot :option="option" :small="true" />
        </div>
        <div class="cartitem-name">
            <b>{{ option.label }}</b> - {{ option.config.quantity }} unités
        </div>
        <div class="cartitem-price">
            xx,xx € HT
        </div>
        <button class="cartitem-delete" title="Supprimer" @click.prevent="$emit('delete')"></button>
    </div>
</template>
