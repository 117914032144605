import Constants from '../../config/Constants'
import _ from 'lodash'
import Vue from 'vue'

const state = {
    items: [],
}

const getters = {
    /**
     * get all items of a type placed on a specific marquage
     */
    itemsByMarquage: (state) => ({type, marquage}) => {
        let items = {}
        for (const [index, item] of state.items.entries()) {
            if (!item.deleted && item.marquage === marquage && (!type || item.type === type)) {
                items[index] = item
            }
        }
        return items
    },
    /**
     * get selected item for a marquage and type
     */
    selectedItem: (state) => ({type, marquage}) => {
        for (const [index, item] of state.items.entries()) {
            if (item.selected && item.marquage === marquage && item.type === type && !item.deleted) {
                return {
                    index,
                    item,
                }
            }
        }
        return false
    },
    itemsAll: (state) => {
        return state.items
    },
    /**
     * return elements saved in history manager, to revert user changes
     * @param state
     */
    historyVersion: (state, getters) => (marquage) => {
        return {
            items: _.cloneDeep(getters.itemsByMarquage({type: false, marquage}))
        }
    },
    latestItemColor: (state, getters) => (marquage) => {
        const items = getters.itemsByMarquage({type: false, marquage})
        let color = Constants.DEFAULT_COLOR
        for(const item of Object.values(items).reverse()) {
            if(item.color) {
                if(typeof item.color === 'string') {
                    color = item.color
                    break
                } else if(typeof item.color === 'object') {
                    color = item.color[0]
                    break
                }
            } else if (item.options && item.options.dominantColor && item.options.nbColors < 3) {
                color = item.options.dominantColor
                break
            }
        }
        return color
    },
    itemsColors: (state, getters) => (marquage) => {
        const items = getters.itemsByMarquage({type: false, marquage})
        let colors = {}
        for(const item of Object.values(items).reverse()) {
            if(item.color) {
                if(typeof item.color === 'string') {
                    colors[item.color] = true
                } else if(typeof item.color === 'object') {
                    colors[item.color[0]] = true
                }
            } else if (item.options && item.options.dominantColor && item.options.nbColors < 3) {
                colors[item.options.dominantColor] = true
                if(item.options.nbColors > 1 && item.options.color2) {
                    colors[item.options.color2] = true
                }
            }
        }
        return Object.keys(colors)
    }
}

const actions = {
    /**
     * update item color, if 1 color technique, update all items color
     * @param commit
     * @param getters
     * @param state
     * @param rootGetters
     * @param index
     * @param color
     */
    updateItemColor({commit, getters, state, rootGetters}, {index, color}) {
        const item = state.items[index]
        const currentTechnique = rootGetters['marquage/currentTechnique'](item.marquage)

        if(currentTechnique && currentTechnique.nbColorIncluded === 1) {
            const marquageItems = getters['itemsByMarquage']({marquage: item.marquage})
            for(let iIndex of Object.keys(marquageItems)) {
                if(iIndex !== index.toString()) {
                    commit('updateItemColor', {index: iIndex, color})
                }
            }
        }
        commit('updateItemColor', {index, color})
    },
    offsetItemsPosition({commit, getters, state}, {marquage, direction, offset}) {
        for(const [index, item] of state.items.entries()) {
            if(item.marquage === marquage) {
                commit('updateItemProperty', {
                    index: index,
                    name: direction,
                    value: item[direction] + offset
                })
            }
        }
    }
}

const mutations = {
    /**
     * init items from product json
     * @param state
     * @param items
     */
    initItems(state, { items }) {
        state.items = items
    },
    addItem(state, {marquage, type, defaultValues}) {
        let item = {
            type: type,
            marquage: marquage,
            content: '',
            added: true,
            x: null,
            y: null,
            width: 200,
            height: 200,
            rotation: 0,
            selected: false,
            deleted: false
        }
        if(!defaultValues) {
            defaultValues = {}
        }

        // text specific properties
        if (type === Constants.EDITOR.TYPE_TEXT) {
            item = {
                ...item,
                color: defaultValues.color ? defaultValues.color : Constants.DEFAULT_COLOR,
                fontSize: defaultValues.fontSize ? defaultValues.fontSize : 0,
                fontFamily: defaultValues.fontFamily ? defaultValues.fontFamily : 'Arial',
                align: defaultValues.align ? defaultValues.align : 'center',
                bold: defaultValues.bold ? defaultValues.bold : false,
                italic: defaultValues.italic ? defaultValues.italic : false,
                underline: defaultValues.underline ? defaultValues.underline : false,
                lineHeight: defaultValues.lineHeight ? defaultValues.lineHeight : 1,
            }
        } else if(type === Constants.EDITOR.TYPE_IMAGE) {
            item = {
                ...item,
                imageObj: defaultValues.imageObj,
                originalObj: defaultValues.originalObj,
                options: defaultValues.options,
                cropOptions: defaultValues.cropOptions
            }
        } else if(type === Constants.EDITOR.TYPE_CLIPART) {
            item = {
                ...item,
                clipart: {
                    ...defaultValues.clipart
                },
                color: defaultValues.color ? defaultValues.color : Constants.DEFAULT_COLOR,
            }
        }

        state.items.push(item)
    },
    removeItem(state, index) {
        state.items[index].deleted = true
    },
    selectItem(state, index) {
        for (const i in state.items) {
            state.items[i].selected = false
        }
        if(index) {
            state.items[index].selected = true
        }
    },
    updateItemProperty(state, {index, name, value}) {
        state.items[index][name] = value
    },
    updateItemColor(state, {index, color}) {
        const item = state.items[index]
        if(item.type === Constants.EDITOR.TYPE_IMAGE) {
            item.options.dominantColor = color
        } else {
            item.color = color
        }
    },
    updateItemProperties(state, {index, properties}) {
        for(let name of Object.keys(properties)) {
            state.items[index][name] = properties[name]
        }
    },
    updateItemPosition(state, {index, x, y, w, h, rotation}) {
        state.items[index].x = Math.round(x)
        state.items[index].y = Math.round(y)
        state.items[index].width = Math.round(w)
        state.items[index].height = Math.round(h)
        state.items[index].rotation = Math.round(rotation)
    },
    /**
     * restore history version of items for a marquage
     * @param state
     * @param version
     * @param marquage
     */
    setHistoryVersion(state, {version, marquage}) {
        for (const [index, item] of state.items.entries()) {
            const oldItem = version.items[index]
            if(oldItem) {
                Vue.set(state.items, index, {
                    ...item,
                    ...oldItem
                })
            } else if(state.items[index] && state.items[index].marquage === marquage) {
                state.items[index].deleted = true
            }
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
