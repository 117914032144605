<template>
    <section class="sidebar-section">
        <div class="sidebar-section-header inner box has-large-padding">
            <h1 class="sidebar-title">{{ $t('colorsquantity') }}</h1>
            <div class="variant" v-if="hasMultipleVariants">
                <!-- selected options -->
                <div class="variant-item"
                    v-for="(option, optionSlug) in variant.options"
                    :key="optionSlug"
                    @click="selectOption(optionSlug)"
                    v-show="option.config.quantity > 0"
                >
                    <VariantSpot :option="option" :selected="variant.selected === optionSlug" />
                    <div class="variant-item-quantity">
                        &times; {{ option.config.quantity }}
                    </div>
                </div>
                <button class="lightbutton is-bold" @click="$store.commit('setSidebarToggle', 'variant-' + slug)">
                    <svg-sprite icon="modifier" width="17" height="17" />
                    <span>{{ $t('update') }}</span>
                </button>
            </div>
            <div class="variant" v-else>
                <!-- only one option, chose quantity -->
                <div class="variant-item"
                     v-for="(option, optionSlug) in variant.options"
                     :key="optionSlug"
                >
                    <input type="number" class="variant-quantity-input is-large" min="0"
                           :value="option.config.quantity > 0 ? option.config.quantity : ''"
                           @input="setQuantity($event, optionSlug)" :placeholder="$t('quantity')">
                    <Stock :count="option.stock" />
                </div>
            </div>
        </div>
        <SidebarToggle :slug="'variant-' + slug">
            <div class="sidebar-subtitle">{{ $t('stockAvailable') }}</div>
            <swiper class="variant-list" :class="{'has-subvariants': hasSubVariants}" :options="swiperOptions">
                <swiper-slide
                    v-for="(option, optionSlug) in variant.options"
                    :key="optionSlug"
                >
                    <div
                        class="variant-list-item"
                        @click="selectOption(optionSlug)"
                        :class="{'has-subvariants': option.hasSubVariants, 'is-selected': variant.selected === optionSlug}"
                    >
                        <VariantSpot :option="option" :selected="variant.selected === optionSlug" />
                        <template v-if="!option.hasSubVariants">
                            <!-- no subvariant, just one quantity and stock -->
                            <input type="number" class="variant-quantity-input" min="0" :value="option.config.quantity > 0 ? option.config.quantity : ''"
                                   @input="setQuantity($event, optionSlug)" :placeholder="$t('quantity')">
                            <Stock :count="option.stock" :small="true" />
                        </template>
                    </div>
                </swiper-slide>
            </swiper>
            <div v-if="hasSubVariants" class="variant-submenu">
                <!-- subvariants, display stock and quantity for each -->
                <div class="variant-submenu-title">
                    <VariantSpot :option="selectedOption" :small="true" />
                    {{selectedOption.label}}
                </div>
                <div class="variant-submenu-list">
                    <div class="variant-submenu-item" v-for="subVariant in selectedOption.subVariants" :key="subVariant.id">
                        <div class="variant-item-form">
                            <div class="variant-item-label">{{subVariant.label}}</div>
                            <input type="number" class="variant-quantity-input" min="0"
                                   :class="{ 'is-filled': subVariant.config.quantity > 0 }"
                                   :value="subVariant.config.quantity > 0 ? subVariant.config.quantity : ''"
                                   @input="setQuantity($event, selectedOption.id, subVariant.id)"
                                   placeholder="Quantité">
                        </div>
                        <Stock :count="subVariant.stock" :small="true" />
                    </div>
                </div>
            </div>
        </SidebarToggle>
    </section>
</template>

<script>
    import VariantSpot from '../variant/VariantSpot'
    import Stock from '../ui/Stock'
    import SidebarToggle from './SidebarToggle'
    import { swiper, swiperSlide } from "vue-awesome-swiper"

    export default {
        name: 'SidebarVariant',
        components: {SidebarToggle, Stock, VariantSpot, swiper, swiperSlide},
        data: () => ({
            swiperOptions: {
                slidesPerView: 'auto',
                spaceBetween: 6,
                freeMode: true,
                grabCursor: true
            }
        }),
        props: {
            variant: Object,
            slug: String
        },
        computed: {
            selectedOption() {
                return this.$store.getters['variants/selectedOption'](this.slug)
            },
            hasMultipleVariants() {
                return this.variant.options && Object.values(this.variant.options).length > 1
            },
            /**
             * check if options has sub variants
             */
            hasSubVariants() {
                const option = Object.values(this.variant.options)[0]
                return option && option.hasSubVariants
            }
        },
        methods: {
            /**
             * select a variant option, if already selected display other option choices
             * @param slug
             */
            selectOption(slug) {
                const selectedOption = this.$store.getters['variants/selectedOption'](this.slug)
                if(selectedOption && selectedOption.id === slug) {
                    this.$store.commit('setSidebarToggle', 'variant-' + this.slug)
                } else {
                    this.$store.commit('variants/selectOption', {
                        variant: this.slug,
                        option: slug
                    })
                }
            },
            setQuantity(event, optionSlug, subVariantSlug = null) {
                let qty = 0
                if (event.target.value.length > 0) {
                    qty = event.target.value
                }
                this.$store.commit('variants/setOptionQuantity', {
                    variantId: this.slug,
                    optionId: optionSlug,
                    subVariantId: subVariantSlug,
                    quantity: qty
                })
            }
        }
    }
</script>
