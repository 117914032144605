
export default {
    EDITOR: {
        TYPE_TEXT: 'text',
        TYPE_IMAGE: 'image',
        TYPE_CLIPART: 'clipart'
    },
    MAX_TEXTS: 8,
    FONTS: [
        {
            label: 'Arial',
            family: 'Arial'
        },
        {
            label: 'Times',
            family: 'Times New Roman'
        },
        {
            label: 'Impact',
            family: 'Impact'
        },
        {
            label: 'Segoe',
            family: 'SegoeUI'
        },
        {
            label: 'Comic sans',
            family: 'Comic sans ms'
        },
        {
            label: 'Trebuchet',
            family: 'Trebuchet MS'
        },
        {
            label: 'Georgia',
            family: 'Georgia'
        }
    ],
    DEFAULT_COLOR: '#000'
}
