<template>
    <div>
        <div class="popup-content">
            <div class="popup-main has-no-padding">
                <div class="form" :class="{'is-loading': loading}">
                    <div class="form-loader">
                        <loader/>
                    </div>
                    <div class="popup-preview">
                        <img :src="this.imgSrc" alt="" v-if="this.imgSrc" ref="img">
                        <button class="button is-large is-rounded is-hidden-mobile" @click.prevent="copyImage" :class="{
                            'is-success': copied }">
                            {{ copied ? $t('imageCopied') : $t('copyImage') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'PopupPreview',
        data: () => ({
            loading: false,
            imgSrc: null,
            copied: false
        }),
        mounted() {
            this.generateImage()
        },
        methods: {
            /**
             * generate image from api
             * @returns {Promise<void>}
             */
            async generateImage() {
                this.loading = true
                try {
                    this.imgSrc = await this.$store.dispatch('generatePreview', { annotate: true })
                    this.loading = false
                } catch(error) {
                    this.loading = false
                }
            },
            /**
             * copy image to clipboard
             */
            copyImage() {
                var canvas = document.createElement('canvas')
                canvas.width = this.$refs.img.width
                canvas.height = this.$refs.img.height

                var ctx = canvas.getContext('2d')
                ctx.drawImage(this.$refs.img, 0, 0, canvas.width, canvas.height)

                canvas.toBlob((imgBlob) => {
                    try {
                        navigator.clipboard.write([
                            new ClipboardItem({
                                'image/png': imgBlob
                            })
                        ]);
                        this.copied = true
                    } catch (error) {
                        console.error(error);
                    }
                })
            },
        },
    }
</script>
