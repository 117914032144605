import config from '../../../config.json'
import axios from 'axios'

const state = {
    items: null,
}

const getters = {
    items: (state) => {
        return state.items
    }
}

const actions = {
    /**
     * call api to fetch all items
     * @param commit
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getAll({commit}) {
        return axios
            .get(process.env.VUE_APP_API_URL + 'clipart')
            .then((response) => {
                if(response.data) {
                    const items = {}
                    for(const clipart of response.data) {
                        if(!items[clipart.category.id]) {
                            items[clipart.category.id] = {
                                ...clipart.category,
                                cliparts: []
                            }
                        }
                        items[clipart.category.id].cliparts.push(clipart)
                    }
                    commit('setItems', items)
                }
            })
    }
}

const mutations = {
    /**
     * update items
     * @param state
     * @param items
     */
    setItems(state, items) {
        state.items = items
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
