<template>
    <div class="viewer">
        <div class="viewer-editor js-unselect-item" ref="editor" @mousedown="onEditorClick" :class="{'is-dragged': isDragged}"
             @dragover.prevent="dragHighlight" @drop.prevent="onEditorDrop($event)" @dragenter.self="dragHighlight" @dragleave.self="dragUnhighlight">
            <div class="viewer-editor-drag">
                <svg-sprite classname="toolsbutton-icon" width="40" height="40" icon="telecharger" />
                <p>{{ $t('imageupload.drop') }}</p>
            </div>
            <transition-group name="fade">
                <ViewerProduct
                    v-for="(marquage, slug) in marquages"
                    :key="slug"
                    v-show="slug === selectedSlug"
                    :marquageSlug="slug"
                    :marquage="marquage"
                    :viewerSize="viewerSize"
                    :active="slug === selectedSlug"
                    :show-rules="showRules"
                    :dark-mode="darkMode"
                ></ViewerProduct>
            </transition-group>
        </div>
        <div class="viewer-tools">
            <button class="toolsbutton is-hidden-mobile" @click.prevent="$emit('zoom', 1)">
                <svg-sprite classname="toolsbutton-icon" width="40" height="40" icon="zoommore" />
                <span class="toolsbutton-text">{{ $t('zoommore') }}</span>
            </button>
            <button class="toolsbutton is-hidden-mobile" @click.prevent="$emit('zoom', -1)">
                <svg-sprite classname="toolsbutton-icon" width="40" height="40" icon="zoomless" />
                <span class="toolsbutton-text">{{ $t('zoomless') }}</span>
            </button>
            <button class="toolsbutton is-hidden-mobile" @click.prevent="historyNavigate(-1)" :disabled="!canGoBack">
                <svg-sprite classname="toolsbutton-icon" width="40" height="40" icon="annuler" />
                <span class="toolsbutton-text">{{ $t('undo') }}</span>
            </button>
            <button class="toolsbutton is-hidden-mobile" @click.prevent="historyNavigate(1)" :disabled="!canGoForward">
                <svg-sprite classname="toolsbutton-icon" width="40" height="40" icon="retablir" />
                <span class="toolsbutton-text">{{ $t('redo') }}</span>
            </button>
            <button class="toolsbutton is-hidden-mobile" @click.prevent="toggleRules">
                <svg-sprite classname="toolsbutton-icon" width="40" height="40" icon="regles" />
                <span class="toolsbutton-text">{{ $t('regles') }}</span>
            </button>
            <button class="toolsbutton" @click.prevent="openPopup('PopupShare')">
                <svg-sprite classname="toolsbutton-icon" width="40" height="40" icon="partager" />
                <span class="toolsbutton-text">{{ $t('share') }}</span>
            </button>
            <button class="toolsbutton" @click.prevent="openPopup('PopupPreview')">
                <svg-sprite classname="toolsbutton-icon" width="40" height="40" icon="telecharger" />
                <span class="toolsbutton-text">{{ $t('download') }}</span>
            </button>
            <button class="toolsbutton" @click.prevent="openPopup('PopupShare')">
                <svg-sprite classname="toolsbutton-icon" width="40" height="40" icon="enregistrer" />
                <span class="toolsbutton-text">{{ $t('save') }}</span>
            </button>
            <ViewerTextOptions></ViewerTextOptions>
            <ViewerImageOptions></ViewerImageOptions>
            <ViewerMotifOptions></ViewerMotifOptions>
            <Cart :is-mobile="true" />
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import ViewerProduct from './ViewerProduct'
    import ViewerTextOptions from './ViewerTextOptions'
    import ViewerImageOptions from './ViewerImageOptions'
    import ViewerMotifOptions from './ViewerMotifOptions'
    import colorsTransform from '../../utils/colors'
    import Cart from '../cart/Cart'

    export default {
        name: 'Viewer',
        components: {Cart, ViewerImageOptions, ViewerTextOptions, ViewerProduct, ViewerMotifOptions},
        data: () => ({
            viewerSize: {
                width: 0,
                height: 0,
            },
            canGoBack: false,
            canGoForward: false,
            isDragged: false,
            showRules: 0
        }),
        /**
         * component mounted, init events and viewer size
         */
        mounted() {
            this.onResize()
            window.addEventListener('resize', this.onResize)
            this.$el.addEventListener('mousewheel', this.onWheel)
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize)
            this.$el.removeEventListener('mousewheel', this.onWheel)
        },
        computed: {
            ...mapState({
                marquages: state => state.marquage.items,
                selectedSlug: state => state.marquage.selected,
                history: state => state.history.histories,
                sidebarDesktopToggled: state => state.sidebarDesktopToggled
            }),
            lightMode() {
                return this.$store.getters['product/light']
            },
            /**
             * selected color is dark ? activate dark mode for marking UI
             */
            darkMode() {
                const colors = this.$store.getters['variants/selectedColor']
                if(colors) {
                    const rgb = colorsTransform.hexToRGB(colors[0])
                    const hsl = colorsTransform.rgbToHsl(rgb[0], rgb[1], rgb[2])
                    return hsl[2] < 0.5
                }
                return false
            }
        },
        watch: {
            history: {
                deep: true,
                handler() {
                    this.updateHistoryState()
                }
            },
            selectedSlug() {
                this.updateHistoryState()
            },
            lightMode() {
                setTimeout(() => {
                    this.onResize()
                }, 200)
            },
            sidebarDesktopToggled() {
                requestAnimationFrame(() => {
                    this.onResize()
                })
            }
        },
        methods: {
            /**
             * resize div, update viewer dimensions
             */
            onResize() {
                this.viewerSize = {
                    width: this.$refs.editor.offsetWidth,
                    height: this.$refs.editor.offsetHeight,
                }
            },
            /**
             * zoom
             */
            onWheel(event) {
                event.preventDefault()
                this.$emit('zoom', -event.deltaY / 100)
            },
            /**
             * click one editor, on other element than editable items, unselect item
             * @param event
             */
            onEditorClick(event) {
                if (event.target.classList.contains('js-unselect-item')) {
                    this.$store.commit('editor/selectItem', false)
                }
            },
            historyNavigate(direction) {
                this.$store.dispatch('history/navigate', direction)
            },
            /**
             * marquage or history change, update canGoBack and canGoFoward boolean to disable buttons
             */
            updateHistoryState() {
                const history = this.history[this.selectedSlug]
                if(history) {
                    this.canGoBack = (history.currentVersion > 0)
                    this.canGoForward = (history.currentVersion < history.versions.length - 1)
                }
            },
            /**
             * drop image file(s) on editor, upload it
             */
            onEditorDrop(event) {
                this.dragUnhighlight()
                // todo refactor
                // if (event.dataTransfer.items) {
                //     // Use DataTransferItemList interface to access the file(s)
                //     for (let i = 0; i < event.dataTransfer.items.length; i++) {
                //         // If dropped items aren't files, reject them
                //         if (event.dataTransfer.items[i].kind === 'file') {
                //             const file = event.dataTransfer.items[i].getAsFile()
                //             this.$store.dispatch('library/uploadFile', {file})
                //         }
                //     }
                // } else {
                //     // Use DataTransfer interface to access the file(s)
                //     for (const file of event.dataTransfer.files) {
                //         this.$store.dispatch('library/uploadFile', {file})
                //     }
                // }
            },
            dragHighlight() {
                this.isDragged = true
                this.dragTimeout && clearTimeout(this.dragTimeout)
                this.dragTimeout = setTimeout(() => {
                    this.isDragged = false
                }, 500)
            },
            dragUnhighlight() {
                this.isDragged = false
            },
            openPopup(name) {
                this.$store.commit('popup/open', {slug: name})
            },
            toggleRules() {
                this.showRules++
                if(this.showRules > 2) {
                    this.showRules = 0
                }
            }
        },
    }
</script>
