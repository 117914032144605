<template>
    <transition name="fade">
        <div class="viewer-product-image" v-if="showed">
            <img :src="image.url.replace('web-tab', 'localhost:8000')" alt="">
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'ViewerProductImage',
        props: {
            image: Object,
            showed: Boolean
        },
        computed: {
            /**
             * do we show image, based on variants selection
             * @returns {boolean}
             */
            showed2() {
                const options = this.$store.getters['variants/selectedOptions']
                let showed = true
                if(this.image.variant) {
                    for(const variant in options) {
                        // image condition not corresponding to user choice, do not display it
                        if(this.image.variant !== options[variant]) {
                            showed = false
                        }
                    }
                }
                return showed
            }
        }
    }
</script>
