import config from '../../../config.json'
import axios from 'axios'

const state = {
    prices: {
        total: 0,
        details: []
    },
}

const getters = {
    prices: (state) => {
        return state.prices
    }
}

const actions = {
    /**
     * call api to calculate price for current customization
     * @param commit
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    updatePrice({commit}, data) {
        return axios
            .post(process.env.VUE_APP_API_URL + 'price/getForProduct', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                commit('setPrices', response.data)
            })
    }
}

const mutations = {
    /**
     * update cart prices : total + details array
     * @param state
     * @param prices
     */
    setPrices(state, prices) {
        state.prices = {
            ...state.prices,
            ...prices
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
