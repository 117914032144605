<template>
    <div class="sidebar-light" @click="openPopup">
        <div class="sidebar-light-title">Dimo Custom Light</div>
        <div class="sidebar-light-icon">
            <svg-sprite icon="information" width="25" height="25" />
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            openPopup() {
                this.$store.commit('popup/open', { slug: 'PopupLightinfo' })
            }
        }
    }
</script>
