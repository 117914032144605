<template>
    <div class="cart">
        <div class="cart-prices">
            <div class="cart-prices-body" :class="{ 'is-loading': loading }">
                <template v-for="(variant, slug) in variants">
                    <CartItemVariant
                        v-for="(option, optionSlug) in variant.options"
                        :key="optionSlug"
                        :option="option"
                        v-if="option.config.quantity > 0"
                        @delete="deleteVariant(slug, optionSlug)"
                    />
                </template>
                <template v-for="(marquage, marquageSlug) in marquages">
                    <template
                        v-for="(technique, techniqueIndex) in marquage.types"
                    >
                        <CartItemTechnique
                            v-for="variant in technique.variants"
                            :key="variant.id"
                            :variant="variant"
                            :technique="technique"
                            :marking="marquage"
                            v-if="marquage.config.markingTypeVariantId === variant.id"
                            @delete="deleteTechnique(marquageSlug)"
                        />
                    </template>
                </template>
            </div>
        </div>
        <div class="cart-footer">
            <div class="cart-footer-title">
                <b>Total produits</b>
                tout inclus, {{ totalQuantity }} unités
            </div>
            <div class="cart-footer-prices" @click.prevent="$store.commit('toggleSidebar')">
                <div class="cart-footer-total"><b>{{ prices.total | price }}</b> HT</div>
                <div class="price" v-if="prices.unit"><span class="price-value-prefix">soit </span> <span class="price-value">{{ prices.unit | price }}</span> / unité</div>
            </div>
        </div>
        <div class="cart-submit">
            <button class="cart-submit-button button is-primary" @click="validateCart" :class="{'is-loading': validateLoading}">
                Ajouter au panier
            </button>
        </div>
        <form
            action="http://v17.dimo.fr/index.php?fc=module&module=dimocustom&controller=add"
            method="post"
            ref="validateForm"
        >
            <input type="hidden" name="token" :value="token">
            <input type="hidden" name="id_product" :value="idProduct">
            <input type="hidden" name="id_customization" :value="jsonId">
            <input type="hidden" name="id_customization_module" value="2">
        </form>
    </div>
</template>

<script>
    // import VueSlider from 'vue-slider-component'
    import { mapState, mapGetters } from 'vuex'
    import Loader from '../ui/Loader'
    import CartTotal from './CartTotal'
    import axios from 'axios'
    import CartItemVariant from "./CartItemVariant.vue";
    import SidebarVariant from "../sidebar/SidebarVariant.vue";
    import CartItemTechnique from "./CartItemTechnique.vue";

    export default {
        name: 'Cart',
        components: {
            CartItemTechnique,
            SidebarVariant,
            CartItemVariant,
            CartTotal,
            // VueSlider
            Loader
        },
        props: {
            isMobile: Boolean
        },
        data: () => ({
            updatePriceInterval: false,
            loading: false,
            needUpdate: false,
            token: '',
            jsonId: '',
            validateLoading: false
        }),

        mounted() {
            // get cart token in get params
            const urlParams = new URLSearchParams(window.location.search)
            this.token = urlParams.get('token')
        },

        computed: {
            ...mapState({
                marquages: state => state.marquage.items
            }),
            ...mapGetters({
                prices: 'cart/prices',
                variants: 'variants/all',
                editorItems: 'editor/itemsAll',
                markingId: 'marquage/selected',
                idProduct: 'product/idProduct',
                totalQuantity: 'variants/totalQuantity'
            }),
            selectedTechnique() {
                return this.$store.getters['marquage/currentTechnique'](this.markingId)
            }
        },
        watch: {
            variants: {
                deep: true,
                handler() {
                    this.priceItemChanged()
                }
            },
            editorItems: {
                deep: true,
                handler() {
                    this.priceItemChanged()
                }
            },
            markingId() {
                this.priceItemChanged()
            },
            'selectedTechnique.id'() {
                this.priceItemChanged()
            }
        },
        methods: {
            /**
             * an information that can change price has been modified, update price not too frequently
             */
            priceItemChanged() {
                this.updatePriceInterval && clearTimeout(this.updatePriceInterval)
                this.updatePriceInterval = setTimeout(() => {
                    if (!this.loading) {
                        this.updatePrice()
                    } else {
                        this.needUpdate = true
                    }
                }, 1000)
            },
            async updatePrice() {
                if (this.isMobile) {
                    return
                }
                this.loading = true
                const custom = await this.$store.dispatch('getCustomization')
                // console.log(custom)
                this.$store.dispatch('cart/updatePrice', custom)
                    .finally(() => {
                        this.loading = false
                        // params have changed during loading, load again
                        if (this.needUpdate) {
                            this.needUpdate = false
                            this.updatePrice()
                        }
                    })
            },
            toggleDetails() {
                this.showDetails = !this.showDetails
            },
            /**
             * validate cart, save current custom json and redirect to shop with correct post params
             * @returns {Promise<void>}
             */
            async validateCart() {
                if (this.validateLoading) {
                    return
                }
                this.validateLoading = true

                const custom = await this.$store.dispatch('getCustomization')
                axios.post(process.env.VUE_APP_API_URL + 'save', custom, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(( response ) => {
                        if (response.data && response.data.success) {
                            this.jsonId = response.data.id
                            if(window.parent) {
                                window.parent.postMessage({
                                    type: 'configuratorValidate',
                                    custom: custom
                                }, '*')
                            }
                        }
                    })
                    .catch(( error ) => {
                        this.validateLoading = false
                    })
            },

            deleteVariant(variantSlug, optionSlug) {
                this.$store.commit('variants/setOptionQuantity', {
                    variantId: variantSlug,
                    optionId: optionSlug,
                    subVariantId: null,
                    quantity: 0
                })
            },

            deleteTechnique(marquageSlug) {
                this.$store.commit('marquage/selectTechnique', {
                    markingId: marquageSlug,
                    techniqueIndex: null,
                    variantId: null
                })
            }
        }
    }
</script>
