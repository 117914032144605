<template>
    <div class="variantqty">
        <div class="variantqty-spot">
            <VariantSpot :option="option" />
        </div>
        <div class="variantqty-label" v-if="option.label">
            {{ option.label }} -
        </div>
        <template v-if="option.subVariants">
            <div class="variantqty-field" v-for="subvariant in option.subVariants" @click="selectSubvariant(subvariant)">
                <div>{{ subvariant.label }}</div>
                <input type="number" class="variantqty-input" min="0"
                       :value="subvariant.config.quantity > 0 ? subvariant.config.quantity : ''"
                       @input="setQuantity($event, optionSlug, subvariant.id)">
            </div>
        </template>
        <template v-else>
            <div class="variantqty-field">
                <div>{{ $t('quantity') }}</div>
                <input type="number" class="variantqty-input" min="0"
                       :value="option.config.quantity > 0 ? option.config.quantity : ''"
                       @input="setQuantity($event, optionSlug)">
            </div>
        </template>
    </div>
</template>

<script>
    import VariantSpot from "../variant/VariantSpot.vue"

    export default {
        components: { VariantSpot },
        props: {
            variantSlug: String,
            option: Object,
            optionSlug: String
        },
        methods: {
            setQuantity(event, optionSlug, subVariantSlug = null) {
                let qty = 0
                if (event.target.value.length > 0) {
                    qty = event.target.value
                }
                this.$store.commit('variants/setOptionQuantity', {
                    variantId: this.variantSlug,
                    optionId: optionSlug,
                    subVariantId: subVariantSlug,
                    quantity: qty
                })
            },
            selectSubvariant(subvariant) {
                console.log(subvariant);
                this.$store.commit('variants/selectSubvariant', {
                    subvariantId: subvariant.id
                })
            }
        }
    }
</script>
