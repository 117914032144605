<template>
    <div>
        <div class="popup-header">
            <div class="popup-title">{{ $t('imageEdit.title') }}</div>
            <div class="popup-subtitle">{{ $t('imageEdit.subtitle') }}</div>
        </div>
        <div class="popup-content popup-imageedit">
            <div class="popup-side">
                <div class="popup-options">
                    <!-- Colors -->
                    <div class="popup-option is-colors" v-if="nbColors < 3">
                        <div class="popup-option-label is-hidden-mobile">
                            <span class="text">
                                {{ $tc('imageEdit.color', nbColors) }}
                                ({{nbColorDisplay}})
                            </span>
                        </div>
                        <div class="popup-option-control" :class="{'is-colors': nbColors > 1}">
                            <ColorPicker v-model="options.dominantColor" @change="optionChanged" :disabled="disableColorEdition"/>
                            <ColorPicker v-model="options.color2" @change="optionChanged" v-if="nbColors === 2"/>
                        </div>
                    </div>

                    <!-- Invert colors -->
                    <div class="popup-option is-inline is-invert" v-if="nbColors === 1">
                        <div class="popup-option-label">
                            <span class="text">
                                {{ $t('imageEdit.invert') }}
                            </span>
                        </div>
                        <div class="popup-option-control">
                            <label class="toggle" for="edit-image-negate">
                                <input type="checkbox" class="checkbox" v-model="options.negate" @change="optionChanged" id="edit-image-negate">
                                <div class="toggle-item"></div>
                            </label>
                        </div>
                    </div>

                    <!-- Background delete -->
                    <div class="popup-option is-inline is-background">
                        <div class="popup-option-label">
                            <span class="text">
                                {{ $t('imageEdit.backgroundDelete') }}
                            </span>
                        </div>
                        <div class="popup-option-control">
                            <vue-slider v-model="options.threshold" :adsorb="true" :height="1" :min="0" :max="100"
                                        tooltip="none" @change="optionChanged" :lazy="true"></vue-slider>
                        </div>
                    </div>

                    <!-- Reinit options
                    <div class="popup-option is-hidden-mobile">
                        <a class="popup-option-label" href="#" @click.prevent="reinit">
                            <span class="text">
                                {{ $t('imageEdit.reverse') }}
                            </span>
                        </a>
                    </div>-->

                    <!-- info message for quadricolor -->
                    <div class="popup-option" v-if="nbColors > 2">
                        <div class="popup-option-label">
                            <span class="text">
                                {{ $t('imageEdit.informations') }} :
                            </span>
                        </div>
                        <div class="popup-option-control">
                            <p v-html="$t('imageEdit.informationsText')"></p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="popup-main form" :class="{'is-loading': loading}">
                <div class="form-loader">
                    <loader/>
                </div>
                <div class="cropper-container">
                    <div class="image">
                        <img v-if="previewUrl" :src="previewUrl" alt="">
                    </div>
                </div>
                <div class="form-confirm-button">
                    <button class="button is-large is-rounded is-bordered" @click.prevent="cancel">{{ $t('undo') }}
                    </button>
                    <button class="button is-large is-rounded" :class="{'is-loading': loading}"
                            @click.prevent="validate">{{ $t('apply') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VueSlider from 'vue-slider-component'
    import ColorPicker from '../../picker/ColorPicker'
    import Constants from '../../../config/Constants'

    export default {
        name: 'PopupImageEdit',
        components: {ColorPicker, VueSlider},
        data: () => ({
            loading: false,
            options: {
                dominantColor: null,
                color2: null,
                negate: false,
                threshold: 50,
            },
            isLaser: false,
            isQuadri: false,
            nbColors: null,
            image: null,
            previewUrl: null,
            disableColorEdition: false
        }),
        mounted() {
            this.image = this.$store.state.popup.params.image
            this.originalImageId = this.image.originalObj ? this.image.originalObj.id : this.image.imageObj.id

            // get nb colors from selected technique
            const currentTechnique = this.$store.getters['marquage/currentTechnique'](this.$store.getters['marquage/selected'])
            this.nbColors = currentTechnique ? currentTechnique.nbColorIncluded : 4

            // if edit mode, get options from image
            if (this.$store.state.popup.params.edit) {
                this.options = {...this.image.options}
            }

            // check if technique is quadri
            if(currentTechnique.label === 'quadri') {
                this.isQuadri = true
            }

            // do we have laser color ? disable color change
            if(currentTechnique.laserColor) {
                this.options.dominantColor = currentTechnique.laserColor
                this.disableColorEdition = true
                this.isLaser = true
            }

            this.generateImage()
            this.initialOptions = {
                ...this.options,
            }
        },
        computed: {
            message: {
                set(value) {
                    this.$store.commit('product/setHelp', value)
                },
                get() {
                    return this.$store.getters['product/help']
                },
            },
            /**
             * display colors number, laser or quadri
             */
            nbColorDisplay() {
                if(this.isLaser) {
                    return 'Laser'
                }
                if(this.isQuadri) {
                    return 'Quadri'
                }
                return this.nbColors
            }
        },
        methods: {
            reinit() {
                this.options = {
                    ...this.initialOptions,
                }
                this.generateImage()
            },
            /**
             * any option changed, generate a new image
             */
            optionChanged() {
                this.generateImage()
            },
            generateImage(save = false) {
                this.loading = true
                this.$store.dispatch('library/editImage', {
                    options: this.options,
                    cropOptions: this.image ? this.image.cropOptions : null,
                    save: save,
                    nbColors: this.nbColors,
                    id: this.originalImageId,
                })
                    .then((response) => {
                        if (save) {
                            this.loading = false
                            let index

                            if (this.$store.state.popup.params.edit) {
                                // image already present in items, update it
                                this.$store.commit('editor/updateItemProperties', {
                                    index: this.$store.state.popup.params.index,
                                    properties: {
                                        imageObj: {...response.obj},
                                        options: {...this.options, nbColors: this.nbColors}
                                    }
                                })
                                index = this.$store.state.popup.params.index
                            } else {
                                // add image to editor items
                                this.$store.commit('editor/addItem', {
                                    marquage: this.$store.getters['marquage/selected'],
                                    type: Constants.EDITOR.TYPE_IMAGE,
                                    defaultValues: {
                                        imageObj: response.obj,
                                        originalObj: {...this.image.imageObj},
                                        options: {...this.options, nbColors: this.nbColors},
                                        cropOptions: null,
                                    },
                                })
                                index = this.$store.state.editor.items.length - 1
                            }

                            if(this.nbColors === 1) {
                                // update item color, to also update texts color
                                this.$store.dispatch('editor/updateItemColor', {
                                    index: index,
                                    color: this.options.dominantColor
                                })
                            }

                            this.$store.dispatch('history/snapshot')
                            this.$store.commit('popup/close')
                        } else {
                            // colors not defined ? get from api response
                            if(!this.options.dominantColor) {
                                this.options.dominantColor = response.dominantColor
                            }
                            if(!this.options.color2 && response.secondaryColor && response.secondaryColor !== 'null') {
                                this.options.color2 = response.secondaryColor
                            }

                            // load image
                            const newImg = new Image
                            newImg.onload = () => {
                                this.previewUrl = newImg.src
                                this.loading = false
                            }
                            newImg.src = response.imageUrl
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        this.loading = false
                    })
            },
            cancel() {
                this.$store.commit('popup/close')
            },
            validate() {
                this.generateImage(true)
            },
        },
    }
</script>
