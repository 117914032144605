<template>
    <section class="sidebar-section">
        <div class="sidebar-section-header inner box has-large-padding">
            <h1 class="sidebar-title">{{ $t('imageupload.title') }}</h1>
            <button class="lightbutton" @click.prevent="openPopup('PopupLibrary')">
                <svg-sprite icon="ajouter" width="17" height="17" />
                <span>{{ $t('imageupload.add') }}</span>
            </button>
            <button class="lightbutton" @click.prevent="openPopup('PopupMotifs')">
                <svg-sprite icon="ajouter" width="17" height="17" />
                <span>{{ $t('imageupload.addOpenSource') }}</span>
            </button>

            <div v-if="libraryImages.length">
                <div class="sidebar-label">Logos récents</div>
                <swiper class="sidebar-images" :options="swiperOptions">
                    <swiper-slide v-for="image in libraryImages.slice(0, 8)" :key="image.id">
                        <img :src="image.url" alt="" @click="onClickImage(image)" @error="onImageError(image)">
                        <button class="sidebar-images-delete" @click="onClickImageDelete(image)">
                            <svg-sprite icon="supprimer" width="14" height="14" />
                        </button>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </section>
</template>

<script>
    import {mapGetters} from 'vuex'
    import { swiper, swiperSlide } from "vue-awesome-swiper"

    export default {
        name: 'SidebarImageUpload',
        components: {swiper, swiperSlide},
        data: () => ({
            swiperOptions: {
                slidesPerView: 'auto',
                spaceBetween: 15,
                freeMode: true,
                grabCursor: true
            }
        }),
        methods: {
            openPopup(slug) {
                this.$store.commit('popup/open', {slug})
            },
            onClickImage(image) {
                this.$store.commit('popup/open', {
                    slug: 'PopupImageEdit',
                    params: {
                        image: {
                            imageObj: image
                        },
                        edit: false
                    }
                })
            },
            onImageError(image) {
                this.$store.commit('library/removeImage', {id: image.id})
            },
            onClickImageDelete(image) {
                if(confirm('Supprimer ce logo ?')) {
                    this.$store.commit('library/removeImage', {id: image.id})
                }
            }
        },
        computed: {
            ...mapGetters({
                libraryImages: 'library/images'
            })
        }
    }
</script>
