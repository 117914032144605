<template>
    <section class="sidebar-section">
        <div class="sidebar-section-header inner box has-large-padding">
            <div>
                <!-- multiple markings, display list and select techinque for each -->
                <h1 class="sidebar-title">{{ $tc('marking.spots', marquagesCount) }}</h1>
                <swiper class="marquages" :options="swiperOptions">
                    <swiper-slide
                        v-for="(marquage, slug) in marquages"
                        :key="slug"
                    >
                        <SidebarMarquageItem
                            :marquage="marquage"
                            :slug="slug"
                            :selectedSlug="selectedSlug"
                            @selected="selectMarquage($event)"
                        />
                    </swiper-slide>
                </swiper>
            </div>
        </div>

        <!-- technique selection when we select a marking -->
        <SidebarToggle slug="marking-technique" v-if="selectedMarking">
            <div class="sidebar-title">
                <span>{{ $tc('marking.techniques', selectedMarking.types.length) }}</span>
                <button class="buttoninfo" @click="openTechniquePopup">En savoir +</button>
            </div>
            <div class="technique-variants">
                <template
                     v-for="(technique, techniqueIndex) in selectedMarking.types"
                >
                    <div class="technique-variant"
                         v-for="variant in technique.variants"
                         :key="variant.id"
                         @click="selectTechnique(selectedMarking.id, selectedMarking.config.markingTypeVariantId === variant.id ? null : techniqueIndex, variant.id)"
                         :class="{'is-active': selectedMarking.config.markingTypeVariantId === variant.id}"
                         :style="{order: variant.nbColorIncluded}"
                    >
                        {{variant.label}} ({{technique.label}})
                    </div>
                </template>
            </div>
        </SidebarToggle>
    </section>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import { swiper, swiperSlide } from "vue-awesome-swiper"
    import SidebarToggle from './SidebarToggle'
    import SidebarMarquageItem from './SidebarMarquageItem'

    export default {
        name: 'SidebarMarquages',
        components: {SidebarMarquageItem, SidebarToggle, swiper, swiperSlide},
        data: () => ({
            swiperOptions: {
                slidesPerView: 'auto',
                spaceBetween: 5,
                freeMode: true,
                grabCursor: true
            }
        }),
        computed: {
            ...mapState({
                marquages: state => state.marquage.items,
                selectedSlug: state => state.marquage.selected
            }),
            ...mapGetters({
                selectedMarking: 'marquage/selectedItem'
            }),
            marquagesCount() {
                return this.marquages ? Object.keys(this.marquages).length : 0
            },
            techniquesCount() {
                let count = 0
                for(const m of Object.values(this.marquages)) {
                    if(m.types) {
                        count += Object.keys(m.types).length
                    }
                }
                return count
            }
        },
        methods: {
            selectMarquage(slug) {
                this.$store.dispatch('marquage/selectItem', slug)
                this.$store.commit('setSidebarToggle', 'marking-technique')
            },
            selectTechnique(markingId, techniqueIndex, variantId = null) {
                this.$store.commit('marquage/selectTechnique', {
                    markingId,
                    techniqueIndex,
                    variantId
                })
            },
            openTechniquePopup() {
                this.$store.commit('popup/open', {
                    slug: 'PopupTechniques'
                })
            }
        }
    }
</script>
