<template>
    <div class="options-input colorpicker" @click.stop="" :class="{'is-disabled': disabled}">
        <input type="text" placeholder="Votre pantone" @focus="focused = true" v-model="search">
        <div class="colorpicker-current" @click="focused = true">
            <ColorPickerItem :color="value" />
        </div>
        <transition name="opacity">
            <div class="colorpicker-library" v-if="openLibrary && !disabled" ref="libraryEl">
                <div class="colorpicker-colors" v-if="!colorsPreset">
                    <div class="colorpicker-colors-title">
                        {{ $t('colorpicker.library') }}
                    </div>
                    <div class="colorpicker-colors-line" v-for="(line, index) in library" :key="index">
                        <ColorPickerItem
                            :color="color.hex" v-for="color in line" :key="color.hex"
                            @selected="selectColor(color)" :label="color.pantone" />
                    </div>
                </div>
                <div class="colorpicker-colors" v-if="searchedColors.length && !colorsPreset">
                    <div class="colorpicker-colors-title">
                        {{ $t('colorpicker.searched') }}
                    </div>
                    <div class="colorpicker-colors-searched">
                        <ColorPickerItem
                            :color="color.hex" v-for="color in searchedColors" :key="color.hex"
                            @selected="selectColor(color)" :label="color.pantone" />
                    </div>
                </div>
                <div class="colorpicker-colors" v-if="!!colorsPreset">
                    <div class="colorpicker-colors-title">
                        {{ $t('colorpicker.preset') }}
                    </div>
                    <div class="colorpicker-colors-line">
                        <ColorPickerItem
                            :color="color" v-for="color in colorsPreset" :key="color"
                            @selected="selectColor({hex: color})" label="" />
                    </div>
                </div>
            </div>
        </transition>
        <ColorPickerResults :search="search" v-if="search && !disabled" @selected="selectColor($event, true)" />
        <div class="colorpicker-tooltip" v-if="disabled">
            {{ $t('colorpicker.disabled') }}
        </div>
    </div>
</template>

<script>
    import ColorPickerItem from './ColorPickerItem'
    import ColorPickerResults from './ColorPickerResults'
    import {mapGetters} from 'vuex'

    export default {
        name: 'ColorPicker',
        components: {ColorPickerResults, ColorPickerItem},
        props: {
            value: String,
            disabled: Boolean,
            colorsPreset: Array
        },
        data: () => ({
            focused: false,
            search: '',
            library: [
                [
                    {
                        pantone: 'Yellow C',
                        hex: '#ffee04'
                    },
                    {
                        pantone: '123 C',
                        hex: '#fec422'
                    },
                    {
                        pantone: 'Orange 021 C',
                        hex: '#f68e1e'
                    },
                    {
                        pantone: '1665 C',
                        hex: '#f26431'
                    },
                    {
                        pantone: '471 C',
                        hex: '#ce7018'
                    },
                    {
                        pantone: '477 C',
                        hex: '#67311e'
                    }
                ],
                [
                    {
                        pantone: '182 C',
                        hex: '#f9c8cb'
                    },
                    {
                        pantone: 'Violet C',
                        hex: '#a54499'
                    },
                    {
                        pantone: 'Rubine red C',
                        hex: '#e41a78'
                    },
                    {
                        pantone: '185 C',
                        hex: '#ee3a41'
                    },
                    {
                        pantone: '485 C',
                        hex: '#ee2e22'
                    },
                    {
                        pantone: 'Purple C',
                        hex: '#b30c37'
                    }
                ],
                [
                    {
                        pantone: '382 C',
                        hex: '#c2d72f'
                    },
                    {
                        pantone: 'Green C',
                        hex: '#00a78f'
                    },
                    {
                        pantone: '347 C',
                        hex: '#01a261'
                    },
                    {
                        pantone: '336 C',
                        hex: '#016950'
                    },
                    {
                        pantone: '3435 C',
                        hex: '#00502e'
                    }
                ],
                [
                    {
                        pantone: '292 C',
                        hex: '#78bde8'
                    },
                    {
                        pantone: 'Process blue C',
                        hex: '#0093d0'
                    },
                    {
                        pantone: '286 C',
                        hex: '#005dab'
                    },
                    {
                        pantone: 'Blue 072 C',
                        hex: '#1b3f94'
                    },
                    {
                        pantone: 'Violet C',
                        hex: '#313092'
                    },
                    {
                        pantone: '276 C',
                        hex: '#09084e'
                    }
                ],
                [
                    {
                        pantone: '420 C',
                        hex: '#dcdddf'
                    },
                    {
                        pantone: '429 C',
                        hex: '#b2b7bb'
                    },
                    {
                        pantone: '430 C',
                        hex: '#959ca1'
                    },
                    {
                        pantone: '432 C',
                        hex: '#45555f'
                    },
                    {
                        pantone: '433 C',
                        hex: '#112530'
                    }
                ],
                [
                    {
                        pantone: 'Blanc',
                        hex: '#FFFFFF'
                    },
                    {
                        pantone: 'Noir',
                        hex: '#000000'
                    },
                    {
                        pantone: 'Argent',
                        hex: '#a8a9ad'
                    },
                    {
                        pantone: 'Or',
                        hex: '#a29160'
                    }
                ],
            ]
        }),
        methods: {
            selectColor(color, searched = false) {
                if(searched) {
                    // add to searched color list
                    this.$store.commit('library/addSearchedColor', color)
                }
                this.$emit('input', color.hex)
                this.$emit('change', color.hex)
            },
            onBodyClick() {
                if(!this.focused) return
                this.focused = false
                this.search = ''
            }
        },
        watch: {
            openLibrary(newVal) {
                if(newVal) {
                    // check if library isnt out of screen
                    if(this.$refs.libraryEl) {
                        this.$refs.libraryEl.style.transform = 'none'
                    }
                    this.$nextTick(() => {
                        const bounds = this.$refs.libraryEl.getBoundingClientRect()
                        console.log(bounds, window.innerWidth)
                        if(bounds.right > window.innerWidth) {
                            this.$refs.libraryEl.style.transform = 'translate(' + (window.innerWidth - bounds.right - 10) + 'px, 0)'
                        }
                        if(bounds.left < 0) {
                            this.$refs.libraryEl.style.transform = 'translate(' + (-bounds.left + 10) + 'px, 0)'
                        }
                    })
                }
            }
        },
        computed: {
            ...mapGetters({
                searchedColors: 'library/searchedColors'
            }),
            openLibrary() {
                return this.focused && !this.search
            }
        },
        mounted() {
            document.body.addEventListener('click', this.onBodyClick)
        },
        beforeDestroy() {
            document.body.removeEventListener('click', this.onBodyClick)
        }
    }
</script>
