<template>
    <drr
        :x="positionZoomed.x" :y="positionZoomed.y" :w="positionZoomed.w" :h="positionZoomed.h"
        :angle="item.rotation"
        :centerBound="item.rotation ? outerBounds : null"
        :outerBound="item.rotation ? null : outerBounds"
        :selected="item.selected"
        :deselectLocked="true"
        :aspect-ratio="true"
        @change="updatePosition"
        @select="onSelect"
        @deselect="onDeselect"
        :custom-class="{ 'is-motif': true }"
    >
        <div class="drr-inner" ref="inner" v-html="svgCode" :style="svgStyle">
        </div>
        <div class="drr-delete" @click.prevent="deleteItem">
            <svg-sprite icon="corbeille-white" width="17" height="17" />
        </div>
    </drr>
</template>

<script>
    import drr from './drr'
    import EditorItem from '../../mixins/EditorItem'
    import axios from 'axios'

    export default {
        name: 'EditorClipart',
        mixins: [EditorItem],
        components: {
            drr,
        },
        props: {
            item: Object,
            itemIndex: String,
            dimensions: Object,
            zoom: Number,
        },
        data: () => ({
            svgCode: ''
        }),
        mounted() {
            if (this.item.x === null) {
                this._setDefaultPosition()
            }
            this.getSvgCode()
        },
        computed: {
            /**
             * svg style to fill with color
             */
            svgStyle() {
                const color = this.laserColor ? this.laserColor : this.item.color
                return {
                    color: color
                }
            }
        },
        watch: {},
        methods: {
            /**
             * set default position for the item when we add it, center it on marquage area
             * @private
             */
            _setDefaultPosition() {
                const imgRatio = 1,
                    marquageRatio = this.dimensions.marquage.width / this.dimensions.marquage.height
                let imgWidth, imgHeight

                if (imgRatio > marquageRatio) {
                    imgWidth = Math.min(this.dimensions.marquage.width, 85 * this.zoom)
                    imgHeight = imgWidth / imgRatio
                } else {
                    imgHeight = Math.min(this.dimensions.marquage.height, 85 * this.zoom)
                    imgWidth = imgHeight * imgRatio
                }

                this.$store.commit('editor/updateItemPosition', {
                    index: this.itemIndex,
                    x: this.dimensions.marquage.width / this.zoom / 2,
                    y: this.dimensions.marquage.height / this.zoom / 2,
                    w: imgWidth / this.zoom,
                    h: imgHeight / this.zoom,
                    rotation: 0,
                })
                this.$store.dispatch('history/snapshot')
            },
            /**
             * get svg code from api
             */
            getSvgCode() {
                axios.get(process.env.VUE_APP_API_URL + 'clipart/' + this.item.clipart.id)
                    .then((response) => {
                        this.svgCode = response.data
                    })
                    .catch((error) => {
                        alert('impossible de charger le motif, veuillez essayer à nouveau.')
                        this.deleteItem()
                    })
            }
        },
    }
</script>
