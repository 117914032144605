
const state = {
    id: '',
    idProduct: '',
    ref: '',
    name: '',
    description: '',
    help: '',
    light: false
}

const getters = {
    help: state => {
        return state.help
    },
    light: state => {
        return state.light
    },
    idProduct: state => {
        return state.idProduct
    }
}

const actions = {
}

const mutations = {
    /**
     * init with api infos
     * @param state
     * @param api
     */
    init(state, api) {
        state.id = api.id
        state.idProduct = api.idProduct
        state.ref = api.ref
        state.name = api.name
        state.description = api.description
        state.help = api.help
        state.light = api.light
    },
    setHelp(state, help) {
        state.help = help
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
